<script lang="ts" setup>
import type { LayoutModelMenuField } from "~~/server/graphql/datocms/generated";
import type { PublicStoreConfig } from "~~/models/store";

defineProps<{
  menuLinks: Array<LayoutModelMenuField>;
  store: PublicStoreConfig;
}>();

const route = useRoute();

const isOpen = ref<boolean>(false);
watch(() => route.path, () => isOpen.value = false); // close panel when path changes, eg. /nl/catalog -> /nl/contact
watch(() => route.query, () => isOpen.value = false); // close panel when query changes, eg. ?propertyFilters=[1] -> ?propertyFilters=[2]
</script>

<template>
  <UContainer class="h-4xl flex items-center justify-between">
    <NuxtLinkLocale to="/">
      <img :src="`/${store!.code}/logo.svg`" :alt="`${store!.name}`" :title="`${store!.name}`" class="max-w-[124px]">
    </NuxtLinkLocale>
    <span class="p-xs -me-xs flex items-center" role="button" @click="isOpen = true">
      <UIcon :name="isOpen ? 'i-ph-x' : 'i-ph-list'" class="size-xl" />
    </span>

    <USlideover
      v-model="isOpen" side="left" class="top-4xl md:hidden" :ui="{
        wrapper: 'text-[--color-header-text]',
        overlay:
          {
            base: 'top-4xl',
          },
        width: 'min-w-full',
      }"
    >
      <UContainer>
        <hr class="my-xl">
        <HeaderMobileNavigation class="ms-auto" :menu-links="menuLinks" />
        <hr class="my-xl md:hidden">
        <HeaderLocaleSwitcher class="ms-[5%] lg:ms-14" />
      </UContainer>
    </USlideover>
  </UContainer>
</template>

<style scoped></style>
